@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700;800;900&display=swap');



@media (min-width: 1024px) {
    .container-service {
        margin-top: 200px;
        /* margin-bottom: 250px; */
    }
  
}


.cardvideo{
    height: 65vh !important;
    top: -200% !important;


}
.ReactModal__Content{

    overflow: revert !important;
    
    inset: 305px auto auto 404px !important;


}

.btn-service{
 
   
    margin-left: 536px !important;
    width: 20px;
    border-radius: 14px;
    margin-bottom: 6px;
    background: white;

}
.utext {

  color: #87CEEB;
  margin-top: 20;
  
}

.umyservicesheading {
  text-align: center;
  color: #FFC40A;
  font-weight: bold;
  margin-bottom: -20px;
  margin-top: 40px;
  font-size: 50px;
}


.ucontainer {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    gap: 100px 50px;
    padding: 100px 50px;
    
}

.ucontainer .ucard {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    width: 350px;
    max-width: 100%;
    height: 320px;
    background: white;
    border-radius: 20px;
    transition: 0.5s;
    box-shadow: 0 35px 80px rgba(0, 0, 0, 0.15);
}

.ucontainer .ucard:hover {
    height: 400px;
}

.ucontainer .ucard .uimg-box {
    position: absolute;
    top: 20px;
    width: 300px;
    height: 220px;
    background: #000000;
    border-radius: 12px;
    overflow: hidden;
    transition: 0.5s;
    border: 2px solid #FFC40A;
    height: 230px;
}


.ucontainer .ucard:hover .uimg-box {
    top: -100px;
    scale: 0.8;
    box-shadow: 0 12px 35px #FFC40A;
}

.ucontainer .ucard .uimg-box img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.ucontainer .ucard .ucontent {
    position: absolute;
    top: 252px;
    width: 100%;
    height: 35px;
    padding: 0 30px;
    text-align: center;
    overflow: hidden;
    transition: 0.5s;
    
}

.ucontainer .ucard:hover .ucontent {
    top: 130px;
    height: 250px;
}

.ucontainer .ucard .ucontent h2 {
    font-size: 1.5rem;
    font-weight: 700;
    color: var(--clr);
    
}

.ucontainer .ucard .ucontent p {
    color: #333;
}

.ucontainer .ucard .ucontent a {
    position: relative;
    top: 15px;
    display: inline-block;
    padding: 12px 25px;
    text-decoration: none;
    background: var(--clr);
    color: white;
    font-weight: 500;
}

.ucontainer .ucard .ucontent a:hover {
    opacity: 0.8;
    background-color: #000000;
    color: aqua;
}

@media (max-width: 480px) {
    .btn-service {
        margin-left: 330px !important;

    }

    .cardvideo{
        height: 65vh !important;
        top: -32% !important;
        right: -6%;
    
    }
    .ReactModal__Content{
    
       
        inset: 296px auto auto -16px !important; 
        padding: none !important;
    
    }



    .ucontainer .ucard {
        width: 300px;
        border-radius: 15px;
    }

    .ucontainer .ucard .uimg-box {
        width: 230px !important;
        border-radius: 10px;
    }

    .ucontainer .ucard .ucontent p {
        font-size: 0.8rem;
    }

    .container .ucard .ucontent a {
        font-size: 0.9rem;
    }
    .container-service{
        margin-top: 100px;
  margin-bottom: 100px;
    }
    .umyservicesheading{
        font-size: 30px;
        font-weight: bold;
    }
}
.container-service{
    /* margin-top: 250px; */
  /* margin-bottom: 250px; */
}