h1,
h2,
h3,
h4,
h5,
h6 {}
a,
a:hover,
a:focus,
a:active {
    text-decoration: none;
    outline: none;
}

a,
a:active,
a:focus {
    color: #6f6f6f;
    text-decoration: none;
    transition-timing-function: ease-in-out;
    -ms-transition-timing-function: ease-in-out;
    -moz-transition-timing-function: ease-in-out;
    -webkit-transition-timing-function: ease-in-out;
    -o-transition-timing-function: ease-in-out;
    transition-duration: .2s;
    -ms-transition-duration: .2s;
    -moz-transition-duration: .2s;
    -webkit-transition-duration: .2s;
    -o-transition-duration: .2s;
}



.bcontainer{
    position: absolute;
    left: 50%;
    top: 60%;
    transform: translate(-50%,-50%);
    text-align: center;
    margin-top: 50px;
  }
  
  .ubtn{
    border: none;
    margin: 20px;
    padding: 24px;
    width: 220px;
    font-family: "montserrat",sans-serif;
    text-transform: uppercase;
    border-radius: 6px;
    cursor: pointer;
    color: #fff;
    background-size: 200%;
    transition: 0.6s;
  }
  .ubtn:hover{
    background-position: right;
  }
  
  
  .btn1{
    background-image: linear-gradient(to left,#FFC312,#ff8800,#FFC312);
  }


ul {
    margin: 0;
    padding: 0;
    list-style: none;
}

section {
    padding: 60px 0;
   /* min-height: 100vh;*/
}

.sec-title{
position:relative;
z-index: 1;
margin-bottom:60px;
}


.sec-title h2{
  position: relative;
  display: block;
  font-size:50px;
  line-height: 1.28em;
  
  font-weight: bold;
  padding-bottom:18px;
  
  color: #FFC40A;
  font-weight: bold;
  
  
  }

.sec-title h2:before{
position:absolute;
content:'';
left:0px;
bottom:0px;
width:50px;
height:3px;
background-color:#d1d2d6;
}

.sec-title .text{
position: relative;
font-size: 16px;
line-height: 26px;
color: #848484;
font-weight: 400;
margin-top: 35px;
}

.sec-title.light h2{
color: #ffffff;
}

.sec-title.text-center h2:before{
left:50%;
margin-left: -25px;
}

.list-style-one{
position:relative;
}

.list-style-one li{
position:relative;
font-size:16px;
line-height:26px;
color: #222222;
font-weight:400;
padding-left:35px;
margin-bottom: 12px;
}



.list-style-one li a:hover{
color: #44bce2;
}

.btn-style-one{
position: relative;
display: inline-block;
font-size: 17px;
line-height: 30px;
color: #ffffff;
padding: 10px 30px;
font-weight: 600;
overflow: hidden;
letter-spacing: 0.02em;
background-color: #00aeef;

}

.btn-style-one:hover{
background-color: #0794c9;
color: #ffffff;
}
.about-section{
position: relative;
padding: 120px 0 70px;
}

.about-section .sec-title{
margin-bottom: 45px;
}

.about-section .content-column{
position: relative;
margin-bottom: 50px;
}

.about-section .content-column .inner-column{
position: relative;
padding-left: 30px;
}

.about-section .text{
margin-bottom: 20px;
font-size: 16px;
line-height: 26px;
color: #848484;
font-weight: 400;
}

.about-section .list-style-one{
margin-bottom: 45px;
}

.about-section .btn-box{
position: relative;

}

.about-section .btn-box a{
padding: 15px 50px;
}

.about-section .image-column{
position: relative;
}

.about-section .image-column .text-layer{
position: absolute;
right: -110px;
top: 50%;
font-size: 325px;
line-height: 1em;
color: #ffffff;
margin-top: -175px;
font-weight: 500;
}

.about-section .image-column .inner-column{
position: relative;
padding-left: 70px;
padding-bottom: 0px;
}
.about-section .image-column .inner-column .author-desc{
position: absolute;
bottom: 16px;
z-index: 1;
background: #ff8800;
padding: 10px 15px;
left: 96px;
width: calc(100% - 152px);
border-radius: 50px;
}
.about-section .image-column .inner-column .author-desc h2{
font-size: 21px;
letter-spacing: 1px;
text-align: center;
color: #fff;
margin: 0;
}
.about-section .image-column .inner-column .author-desc span{
font-size: 16px;
letter-spacing: 6px;
text-align: center;
color: #fff;
display: block;
font-weight: 400;
}
.about-section .image-column .inner-column:before{
content: '';
position: absolute;
width: calc(50% + 83px);
height: calc(100% + 100px);
top: -50px;
left: -3px;
background: transparent;
z-index: 0;
border: 40px solid #FFC40A;
}

.about-section .image-column .image-1{
position: relative;

}
.about-section .image-column .image-2{
position: absolute;
left: 0;
bottom: 0;
}

.about-section .image-column .image-2 img,
.about-section .image-column .image-1 img {
  box-shadow: 0 20px 30px rgba(8, 13, 62, 0.15); /* Adjust box shadow as needed */
  border-radius: 36px; /* Adjust border radius as needed */
}

.about-section .image-column .video-link{
position: absolute;
left: 70px;
top: 170px;
}

.about-section .image-column .video-link .link{
position: relative;
display: block;
font-size: 22px;
color: #191e34;
font-weight: 400;
text-align: center;
height: 100px;
width: 100px;
line-height: 100px;
background-color: #ffffff;
border-radius: 50%;
box-shadow: 0 30px 50px rgba(8,13,62,.15);
-webkit-transition: all 300ms ease;
-moz-transition: all 300ms ease;
-ms-transition: all 300ms ease;
-o-transition: all 300ms ease;
transition: all 300ms ease;
}

.about-section .image-column .video-link .link:hover{
background-color: #191e34;
}

@media (max-width: 767px) {
  .about-section {
    padding: 60px 0; /* Decreased padding */
    margin-left: 10px; /* Removed margin */
    width: 10px;
  }

  .about-section .content-column,
  .about-section .image-column {
    margin-bottom: 10px;
    padding: 30px;
    margin-left: -20px;
    
  }

  .about-section .text {
    white-space: normal;
    word-wrap: break-word;
    overflow-wrap: break-word;
    margin-left: -25px;
  }
  .bcontainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-left: -25px;


  }


  .ubtn {
    padding: 10px 20px;
    font-size: 12px;
    margin-top: 20px;
    margin-bottom: 20px;
    margin-left: 20px;/* Remove the right margin */
  }
  .container-about{
    padding: -10px;
    margin-left: -5px;
  }
  .sec-title h2{
    font-size: 30px;
  }
  .about-section .image-column .image-1,
  .about-section .image-column .image-2 {
    width: 300px; /* Adjust the width as needed */
    height: 300px; /* Adjust the height as needed */
  }

  .about-section .image-column .image-1 img,
  .about-section .image-column .image-2 img {
    max-width: 100%;
    height: auto;
  }

  .about-section .image-column .inner-column:before {
    width: calc(80% + 1px); /* Adjust the width as needed */
    height: calc(100% + 60px); /* Adjust the height as needed */
    top: -30px;
    left: -15px;
    border-width: 25px;
  }
  .about-section .image-column .inner-column .author-desc {
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 5px; /* Adjust margin as needed */
    background: #ff8800;
    padding: 10px;
    width: 78%;
    border-radius: 50px;
    right: 280px; /* Adjust the left position as needed */
    bottom: -10%;
    
  }

  .about-section .image-column .inner-column .author-desc h2 {
    font-size: 18px; /* Adjust font-size as needed */
    text-align: center;
    margin-bottom: 5px; /* Adjust margin as needed */
  }

  .about-section .image-column .inner-column .author-desc span {
    font-size: 14px; /* Adjust font-size as needed */
    letter-spacing: normal;
    text-align: center;
    display: block;
    font-weight: 400;
  }

}


