.fade-in {
  opacity: 0;
  transition: opacity 2s ease-in-out;
}

.fade-in.active {
  opacity: 1;
}

.heading {
  color: #FFC40A;
}

.welcome {
  color: white;
}
